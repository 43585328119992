import { get } from '@/application/api/implementations/app/index.js';
import { convertCamelToSnake } from '@/application/api/util/keyConverter.js';

/**
 * @param url
 * @param page
 * @param perPage
 * @param search
 * @param sortBy
 * @param descending
 * @param params
 * @return {Promise<AxiosResponse>}
 */
function getPaginated(url, page = 1, perPage = 10, search, sortBy, descending, params = {}) {
  return get(url, {
    params: {
      page,
      perPage,
      ...params,
      search: search || undefined,
      sortBy: sortBy ? convertCamelToSnake(sortBy) : undefined,
      desc: typeof descending !== 'undefined' ? descending ? '1' : '0' : undefined,
    },
  });
}

export {
  getPaginated,
};
