<template>
  <div>
    <VProgressLinear v-if="isNavigating" indeterminate color="primary" class="mb-n1"/>
    <div class="pa-6">
      <v-row align="center" no-gutters>
        <v-col>
          <slot name="header"/>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" no-gutters>
            <slot name="flow-actions"/>
          </v-row>
        </v-col>
      </v-row>
      <slot #default/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLayout',
  data() {
    return { isNavigating: false };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.isNavigating = true;
      next();
    });
    this.$router.afterEach(() => {
      this.isNavigating = false;
    });
    this.$router.onError(() => {
      this.isNavigating = false;
    });
  },
};
</script>
